<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputRadio v-model="data"
                :args="args"
                :db="db"
                :noSave="noSave"
                :options="booleanYesNo"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {general} from "../../options/general";

import QuestionBase from "../../QuestionBase";
import InputRadio from "../../inputs/InputRadio";

export default {
  name: 'FinanciallyDependant',
  mixins: [
    general,
    questionLoadHelpers
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: [
        Number,
        String
      ],
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Is this person financially dependant on you or living with you?',
        subTitle: 'They could be classed as dependant if for example they live in a property that you own ' + 'or rely on you for financial support.',
        tip: null
      },
      db: {
        saveLocation: 'client_person',
        saveField: 'dependant',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>