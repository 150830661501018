<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard v-for="(person, index) in data"
                    :id="'dependant' + index"
                    :key="'dependant' + person.id"
                    :ref="'dependant' + index"
                    v-model="data[index]"
                    :title="`${fullName(person)} (${ person.relationship_to_client})`"
                    type="dependant"
                    :loading="loading"
                    @delete="deleteObject(person.id)"
                    @save="saveObject(person, 'dependant' + index)">
          <DependantShort :key="'dependant-form' + person.id"
                          v-model="data[index]"
                          :objectId="person.id" />
        </ObjectCard>

        <ObjectCard v-if="form"
                    v-model="form"
                    title="New Dependant"
                    type="dependant-add"
                    :loading="loading"
                    @delete="form=null"
                    @save="saveObject(form, db.saveField)">
          <DependantShort v-model="form"
                          :errors-post="errorsPost"
                          :noSave="true" />
        </ObjectCard>

        <b-button v-if="!form"
                  class="btn-question w-100"
                  @click="addDependant">
          <i class="i-Girl text-25 font-weight-800 mr-2"> </i> Add Dependant
        </b-button>
      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import {personHelpers} from "@/mixins/personHelpers";
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {dependantsNumberHelpers} from "./dependantsNumberHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import DependantShort from "../../../../fieldsets/sub/people/DependantShort";

export default {
  name: 'DependantsShort',
  components: {
    DependantShort,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    objectSaveHelpers,
    personHelpers,
    questionLoadHelpers,
    blankForms,
    dependantsNumberHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    numberOfDependants: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      question: {
        title: 'Dependants',
        subTitle: 'Please add a few details for each of your dependants.',
        tip: null,
      },
      db: {
        saveLocation: 'client_person_dependant',
        saveField: 'dependants',
        deleteLocation: 'client_person',
        loadLocation: 'client_person_dependant',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false,
        callbackFunction: this.setNumberOfDependantsData,
        deleteCallbackFunction: this.setNumberOfDependantsData
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: 'Add dependants to proceed',
        customErrorMessageNewOpenForm: "Save dependant before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    addDependant() {
      this.form = null
      this.form = Object.assign({},
          this.personBlankForm)
      this.form.dependant = true
    }
  }
}
</script>
