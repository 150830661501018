import {httpQuestionnaire} from "@/services";

export const dependantsNumberHelpers = {
    computed: {
        dependantsNumber: {
            set(value) {
                this.$emit('numberOfDependants', value)
            },
            get() {
                return this.numberOfDependants
            }
        }
    },
    methods: {
        async setNumberOfDependantsYN(event) {
            // set to no dependants
            if (event === false) {
                await this.patch(0)
            } else {
                // set profileNumberOfChildren to the number of children created
                await this.patch(this.dependants.length)
            }
        },
        setNumberOfDependantsData() {
            this.patch(this.data.length)
        },
        async patch(value) {
            return httpQuestionnaire.patch('client_client', {
                profileDependantsNumber: value
            }).then(
                () => {
                    this.dependantsNumber = value
                    return true
                }
            ).catch(
                error => {
                    console.log(error)
                    return false
                }
            )
        }
    }
}
