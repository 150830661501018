<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :args="args"
        :server-errors="serverErrors"
        :options="booleanYesNo"
        :no-save="false"
        :db="db"
        :disabled="!!dependants.length"
        @jsErrors="jsErrors=$event"
        ref="input"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";
import { dependantsNumberHelpers } from "./dependantsNumberHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'DependantsYN',
  mixins: [general, questionLoadHelpers, dependantsNumberHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    dependants: {
      type: Array,
      required: true
    },
    numberOfDependants: {
      type: Number,
      required: false
    }
  },
  mounted () {
    // set initial question value (yes/no) based on dependants
    if ((this.dependants.length || this.numberOfDependants) && !this.data) {
      this.$refs.input.data = true
    } else if (this.numberOfDependants === 0 && this.data !== false) {
      this.$refs.input.data = false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Is anybody else financially dependant on you or living with you?',
        subTitle:  'For example does another family member live with you or in a property that you own? This does not include partners / spouses.',
        tip: null,
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'dependants_have',
        callbackFunction: this.setNumberOfDependantsYN
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
