import {httpQuestionnaire} from "@/services";

export const childrenNumberHelpers = {
    computed: {
        childrenNumber: {
            set(value) {
                this.$emit('numberOfChildren', value)
            },
            get() {
                return this.numberOfChildren
            }
        }
    },
    methods: {
        async setNumberOfChildrenYN(event) {
            // set to no children
            if (event === false) {
                await this.patchChildrenNumber(0)
            } else {
                // set profileNumberOfChildren to the number of children created
                await this.patchChildrenNumber(this.children.length)
            }
        },
        setNumberOfChildrenData() {
            this.patchChildrenNumber(this.data.length)
        },
        async patchChildrenNumber(value) {

            return httpQuestionnaire.patch('client_client', {
                profileChildrenNumber: value
            }).then(
                () => {
                    this.childrenNumber = value
                    return true
                }
            ).catch(
                error => {
                    console.log(error)
                    return false
                }
            )
        },
    }
}
