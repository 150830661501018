<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :args="args"
        :server-errors="serverErrors"
        :options="booleanYesNo"
        :no-save="false"
        :db="db"
        :disabled="!!children.length"
        @jsErrors="jsErrors=$event"
        ref="input"
      />
  </QuestionBase>
</template>

<script>
import { childrenNumberHelpers } from "./childrenNumberHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { general } from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'ChildrenYN',
  mixins: [general, questionLoadHelpers, childrenNumberHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    children: {
      type: Array,
      required: true
    },
    numberOfChildren: {
      type: Number,
      required: false
    }
  },
  mounted () {
    // set initial question value (yes/no) based on children
    if ((this.children.length || this.numberOfChildren) && !this.data) {
      this.$refs.input.data = true
    }
    // else if (this.numberOfChildren === 0) this.$refs.input.data = false
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Do you have children?',
        subTitle:  'This includes biological or adopted children but not step children.',
        tip: null,
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'children_have',
        callbackFunction: this.setNumberOfChildrenYN
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
