<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Dependants</h3>
    <DependantsYN
        v-model="form.dependants_have"
        :dependants="form.dependants"
        :number-of-dependants="form.profileDependantsNumber"
        @numberOfDependants="form.profileDependantsNumber=$event"
    />
    <div v-if="form.dependants_have" class="question-box with-arrow">
      <DependantsShort
          v-model="form.dependants"
          :number-of-dependants="form.profileDependantsNumber"
          @numberOfDependants="form.profileDependantsNumber=$event"
          @refresh="refresh"
          ref="dependantsShort"
      />
    </div>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";

import DependantsYN from "../../../question/questions/clientProfile/people/DependantsYN";
import DependantsShort from "../../../question/questions/clientProfile/people/DependantsShort";


export default {
  name: 'DependantsEstatePlanningSimple',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DependantsShort,
    DependantsYN
  },
  data() {
    return {
      label: 'Dependants' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refresh() {
      if ('childrenShort' in this.$refs) {
        this.$refs.childrenShort.get()
      }
      if ('dependantsShort' in this.$refs) {
        this.$refs.dependantsShort.get()
      }
    }
  }
};
</script>
