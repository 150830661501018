<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :showNavigationButtons="true"
  >
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";

import QuestionnaireBase from "../../../../components/common/questionnaires/questionnaire";
import ChildrenEstatePlanningSimple from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Children";
import DependantsEstatePlanningSimple
  from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Dependants";

export default {
  name: "EstatePlanningFactFindSimpleDependants",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    ChildrenEstatePlanningSimple,
    DependantsEstatePlanningSimple
  },
  data() {
    return {
      name: 'EstatePlanningFactFindSimpleDependants',
      title: "Children & Dependants",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'ChildrenEstatePlanningSimple',
        'DependantsEstatePlanningSimple',
      ],
    };
  },
};
</script>
